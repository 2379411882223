.copy {
    font-family: var(--font-family-copy-body);
    color: var(--palette-ink);
}

.copy h1,
.copy h2,
.copy h3,
.copy h4,
.copy h5,
.copy h6 {
    font-family: var(--font-family-copy-body);
    line-height: 1.4em;
    padding-bottom: 1rem;
    font-weight: bold;
}

.copy h3 {
    font-size: 1.125rem;
}

.copy h2 {
    font-size: 1.25rem;
}

.copy p {
    padding-bottom: 1rem;
    line-height: 1.4em;
}

.copy ol {
    display: block;
    list-style-type: decimal;
    padding-left: 3ch;
}

.copy ol>li {
    padding-bottom: 1rem;
    line-height: 1.4em;
}

.copy li>ol {
    padding-top: 1rem;
}

.copy ul {
    display: block;
    list-style-type: disc;
    padding-left: 3ch;
}

.copy ul>li {
    padding-bottom: 1rem;
    line-height: 1.4em;
}

.copy li>ul {
    padding-top: 1rem;
}

.copy del {
    text-decoration: line-through;
    color: var(--palette-grey-c);
    opacity: 0.5;
}