.create-header {
    padding: 1rem 1.5rem;
    display: grid;
    grid-gap: 0.5rem;
    grid-template: auto auto / minmax(0, 1fr) auto;
    align-items: center;
}

.create-header>div {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}