.app-header {
    width: var(--full-width);
    display: grid;
    grid-template-columns: auto minmax(0, 1fr) auto;
    grid-gap: 1rem;
    justify-content: space-around;
    padding: 0.25rem 0.75rem;
    background-color: var(--palette-grey-f);
}

.app-header button,
.app-header a {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    border-radius: var(--radius-small);
    color: var(--palette-grey-c);
    background-color: var(--palette-grey-f);
    transition: filter var(--transition-quick);
    font-size: 1rem;
}

.app-header button:hover,
.app-header a:hover {
    filter: brightness(0.95);
}

.app-header button:focus-visible,
.app-header a:focus-visible,
.app-header button:active,
.app-header a:active {
    filter: brightness(0.90);
}

@media screen and (min-width: 60rem) {
    .app-header {
        width: auto;
    }
}