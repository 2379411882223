.tales {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.tales>header {
    padding: 1rem 1.5rem;
}

.tales>div {
    flex: 1;
}

.tales>div section {
    padding: 1rem 1.5rem;
}

.card-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom: 0.5rem;
}

.tales>footer {
    padding: 1rem;
    background-color: var(--palette-grey-b);
    color: var(--palette-grey-f);
}