.tale-card {
    position: relative;
    height: 16rem;
    border-radius: var(--radius-small);
    overflow: hidden;
}

.tale-card:before {
    z-index: 2;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(var(--palette-grey-a-vals), 0.4), rgba(var(--palette-grey-a-vals), 0.8))
}

.tale-card>img {
    position: relative;
    z-index: 1;
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 0.9;
}