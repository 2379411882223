.note {
    display: inline-block;
    text-align: left;
    position: relative;
    padding: 1.5rem 1.5rem 1rem;
    background: linear-gradient(to bottom, var(--palette-grey-e), var(--palette-grey-f) 1rem);
    box-shadow:
        0 0rem 0.25rem 0 rgba(var(--palette-grey-c-vals), 0.1),
        0 0.125rem 0.125rem 0 rgba(var(--palette-grey-c-vals), 0.05);
    overflow: hidden;
    border-radius: 0.125rem 1rem 0.125rem 0.125rem;
    width: 25rem;
    max-width: 100%;
}

.note:after {
    content: '';
    position: absolute;
    top: 0;
    right: -0.2rem;
    width: 1.2rem;
    height: 1rem;
    background: linear-gradient(13deg, var(--palette-grey-e), var(--palette-grey-f));
    box-shadow: 0 0.25rem 0.25rem 0 rgba(var(--palette-grey-c-vals), 0.1),
        0 0.125rem 0.125rem 0 rgba(var(--palette-grey-c-vals), 0.05);
    transform: rotateZ(10deg) rotateX(5deg) rotateY(5deg);
}