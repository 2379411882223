.heading-primary {
    font-weight: bold;
    font-size: 1.75rem;
}

.heading-secondary {
    font-weight: bold;
    font-size: 1.5rem;
}

.heading-tertiary {
    font-weight: bold;
    font-size: 1.125rem;
}