.create-menu {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.create-menu>header {
    padding: 1rem 1.5rem;
}

.create-menu>div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.create-menu>div>section {
    padding: 1.5rem;
}

.create-menu>div>section>header {
    padding-bottom: 1rem;
}

.create-menu>div>section>footer {
    padding-top: 1rem;
}