.app-layout {
    height: var(--full-height);
    width: var(--full-width);
    display: grid;
    grid-template: auto minmax(0, 1fr) auto / minmax(0, 1fr);
    background: var(--palette-app-bg);
}

.app-layout>main {
    overflow: auto;
}

@media screen and (min-width: 60rem) {
    .app-layout {
        grid-template: auto minmax(0, 1fr) / auto minmax(0, 1fr);
    }

    .app-layout>header {
        grid-row: 1 / 2;
        grid-column: 1 / 3;
    }

    .app-layout>main {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
    }

    .app-layout>footer {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
    }
}