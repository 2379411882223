.app-footer nav {
    width: var(--full-width);
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-around;
    padding: 0.25rem;
    background-color: var(--palette-grey-f);
}

.app-footer nav button,
.app-footer nav a {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    border-radius: var(--radius-small);
    background-color: var(--palette-grey-f);
    transition: filter var(--transition-quick);
}

.app-footer nav button:hover,
.app-footer nav a:hover {
    filter: brightness(0.95);
}

.app-footer nav button:focus-visible,
.app-footer nav a:focus-visible,
.app-footer nav button:active,
.app-footer nav a:active {
    filter: brightness(0.90);
}

.app-footer nav button path,
.app-footer nav a path {
    transition: fill var(--transition-quick);
}

.app-footer nav button[aria-current="page"] path,
.app-footer nav a[aria-current="page"] path {
    fill: var(--palette-primary);
}

@media screen and (min-width: 60rem) {
    .app-footer nav {
        width: auto;
        flex-direction: column;
    }
}