.nav-button {
    height: 2.75rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 0 1rem 0 1.125rem;
    line-height: 1.25rem;
    font-weight: bold;
}

.nav-button.icon-only {
    height: 2.75rem;
    width: 2.75rem;
    padding: 0;
}

.nav-button svg {
    width: 1.25rem;
    height: 1.25rem;
}

.nav-button.icon-only svg {
    width: 1.5rem;
    height: 1.5rem;
}