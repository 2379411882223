.nav-to-parent-lid {
    padding: 0 1.25rem;
}

.nav-to-parent-lid a {
    padding: 0.25rem 0.5rem 0.25rem 0.25rem;
}

.nav-to-parent-lid span {
    font-size: 0.75rem;
    line-height: 1rem;
    display: inline-block;
    vertical-align: middle;
    padding-left: 0.25rem;
    color: var(--palette-grey-c);
}

.nav-to-parent-lid svg {
    display: inline-block;
    vertical-align: middle;
    height: 1rem;
    width: 1rem;
}

.nav-to-parent-lid svg path {
    fill: var(--palette-grey-c);
}