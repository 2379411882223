.create-header {
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.create-header h1 {
    font-weight: bold;
    font-size: 1.5rem;
}

.create-header>div {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}