.create-tale {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.create-tale>header {
    padding: 1rem 1.5rem;
}

.create-tale>section {
    padding: 1rem 1.5rem;
}

.create-tale>footer {
    padding: 1rem;
    background-color: var(--palette-grey-b);
    color: var(--palette-grey-f);
}