.road-map {
    box-shadow: inset 0 0 10rem 0.5rem var(--palette-grey-a);
    background: linear-gradient(to bottom, var(--palette-grey-b), var(--palette-grey-a));
    height: 100%;
    overflow: auto;
}

.road-map section {
    padding: 0.5rem;
    text-align: center;
}

.road-map section>ul>li {
    display: inline-block;
    vertical-align: top;
    padding: 1rem;
}