.account {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.account>header {
    padding: 1rem 1.5rem;
}

.account>div {
    flex: 1;
}

.account>div section {
    padding: 1rem 1.5rem;
}

.account>footer {
    padding: 1rem;
    background-color: var(--palette-grey-b);
    color: var(--palette-grey-f);
}