:root {
    /* Grey */
    --palette-grey-a-vals: 23, 21, 24;
    --palette-grey-a: rgb(var(--palette-grey-a-vals));
    --palette-grey-b-vals: 63, 61, 64;
    --palette-grey-b: rgb(var(--palette-grey-b-vals));
    --palette-grey-c-vals: 103, 101, 104;
    --palette-grey-c: rgb(var(--palette-grey-c-vals));

    --palette-grey-d-vals: 213, 211, 214;
    --palette-grey-d: rgb(var(--palette-grey-d-vals));
    --palette-grey-e-vals: 233, 231, 234;
    --palette-grey-e: rgb(var(--palette-grey-e-vals));
    --palette-grey-f-vals: 253, 251, 254;
    --palette-grey-f: rgb(var(--palette-grey-f-vals));

    /* Primary */
    --palette-primary-vals: 156, 191, 61;
    --palette-primary: rgb(var(--palette-primary-vals));
    --palette-primary-bg-vals: 231, 236, 214;
    --palette-primary-bg: rgb(var(--palette-primary-bg-vals));
    --palette-primary-fg-vals: 73, 91, 23;
    --palette-primary-fg: rgb(var(--palette-primary-fg-vals));

    /* Ink */
    --palette-ink-vals: 11, 66, 87;
    --palette-ink: rgb(var(--palette-ink-vals));

    /* App bg */
    --palette-app-bg: linear-gradient(to bottom, rgb(253, 251, 254), rgb(243, 241, 244));
}


@media (prefers-color-scheme: dark) {
    :root {
        /* Grey */
        --palette-grey-a-vals: 253, 251, 254;
        --palette-grey-a: rgb(var(--palette-grey-a-vals));
        --palette-grey-b-vals: 243, 241, 244;
        --palette-grey-b: rgb(var(--palette-grey-b-vals));
        --palette-grey-c-vals: 233, 231, 234;
        --palette-grey-c: rgb(var(--palette-grey-c-vals));

        --palette-grey-d-vals: 73, 71, 74;
        --palette-grey-d: rgb(var(--palette-grey-e-vals));
        --palette-grey-e-vals: 53, 51, 54;
        --palette-grey-e: rgb(var(--palette-grey-e-vals));
        --palette-grey-f-vals: 23, 21, 24;
        --palette-grey-f: rgb(var(--palette-grey-f-vals));

        /* App bg */
        --palette-app-bg: linear-gradient(to bottom, rgb(23, 21, 32), rgb(0, 0, 0));
    }
}