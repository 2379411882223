/*--- Primary Button ---*/

.primary-button {
    border-radius: var(--radius-small);
    background-color: var(--palette-primary-bg);
    color: var(--palette-primary-fg);
    transition: filter var(--transition-quick), opacity var(--transition-quick);
    cursor: pointer;
}

.primary-button svg path {
    fill: var(--palette-primary-fg);
}

.primary-button:hover:not(button),
.primary-button:hover:enabled {
    filter: brightness(95%);
}

.primary-button:active:enabled,
.primary-button:active:not(button),
.primary-button:focus-visible:enabled,
.primary-button:focus-visible:not(button) {
    filter: brightness(90%);
}

.primary-button:disabled {
    opacity: 0.5;
    pointer-events: none;
}


/*--- Secondary Button ---*/

.secondary-button {
    border-radius: var(--radius-small);
    background-color: rgba(var(--palette-grey-a-vals), 0);
    color: var(--palette-grey-c);
    transition: background-color var(--transition-quick), opacity var(--transition-quick);
    cursor: pointer;
}

.secondary-button svg path {
    fill: var(--palette-grey-c);
}

.secondary-button:hover:not(button),
.secondary-button:hover:enabled {
    background-color: rgba(var(--palette-grey-a-vals), 0.05);
}

.secondary-button:active:enabled,
.secondary-button:active:not(button),
.secondary-button:focus-visible:enabled,
.secondary-button:focus-visible:not(button) {
    background-color: rgba(var(--palette-grey-a-vals), 0.1);
}

.secondary-button:disabled {
    opacity: 0.5;
    pointer-events: none;
}